import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
//Theme
import './index.css';
import theme from './_config/theme';
import {ThemeProvider} from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/fr';

/**
 * ThemeProvider permet d'injecter un thème material-ui
 * CssBaseline permet d'ajuster le css en fonction du navigateur utilisé
 * MuiPickersUtilsProvider permet d'utiliser les date pickers de material-ui
 */
ReactDOM.render(
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
                <App/>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
