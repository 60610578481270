import React, {useEffect, useRef, useState} from 'react';

import css from './Preview.module.css';
import ActionsBar from './_components/ActionsBar/ActionsBar';
import {getUrlParams} from '../../_helpers/utils';

function Preview() {
    const urlParams = getUrlParams(window.location.search);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [scale, setScale] = useState(1);
    const [orientation, setOrientation] = useState("portrait");
    const [menuHeight, setMenuHeight] = useState(null);

    // Si le cache vient d'être réinitialisé on repasse clear à false
    window.onload = () => {
        if (urlParams["url"] && urlParams["url"].search("_clear=true") !== -1) {
            let newUrl = "";
            Object.keys(urlParams).forEach((key, index) => {
                newUrl += index === 0 ? "?" : "&";
                if (key === "url") {
                    newUrl += `url=${encodeURIComponent(urlParams["url"].replace("_clear=true", "_clear=false"))}`;
                } else {
                    newUrl += `${key}=${urlParams[key]}`;
                }
            });
            window.history.replaceState(null, "", newUrl);
        }
    };

    const getHeightActionBar = node => {
      if(node !== null){
          setMenuHeight(node.clientHeight);
      }
    };

    useEffect(() => {
        if (urlParams["fullscreen"] === "true") {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        } else {
            handleIframe(1080, 1920);
        }
        const iframeWidthHeight = () => {
            if (urlParams["fullscreen"] === "true") {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }
        };
        window.addEventListener("resize", iframeWidthHeight);
        const url = urlParams["url"].substring(0, urlParams["url"].indexOf('?'));
        document.title = url.replace(/^\/([^/]*).*$/, '$1').split("/")[3];
        return () => window.removeEventListener("resize", iframeWidthHeight);
    }, []);

    const handleOrientation = () => {
        const newScale = handleScale(1, height, width);

        if(newScale !== 0) {
            setWidth(height);
            setHeight(width);
            setScale(newScale);
            setOrientation(orientation => orientation === "landscape" ? "portrait" : "landscape");
        }

    };

    const handleIframe = (w, h) => {
        const newScale = handleScale(1, w, h);
        if (newScale !== 0) {
            setWidth(w);
            setHeight(h);
            setScale(newScale);
        }
    };

    const handleScale = (s, w, h) => {
        s = Math.round(s * 10) / 10;
        if (s === 0) return 0;
        if (w * s < window.innerWidth && h * s < window.innerHeight - menuHeight) {
            return s;
        }
        return handleScale(s - 0.1, w, h);
    };

    return (
        <div className={css.Container}>
            <div ref={getHeightActionBar} className={css.ActionsBarContainer}>
                <ActionsBar
                    orientation={orientation}
                    handleOrientation={handleOrientation}
                    handleIframe={handleIframe}
                    width={width}
                    height={height}
                    scale={scale}
                    urlParams={urlParams}
                />
            </div>
            <div style={{
                ...(urlParams["fullscreen"] !== "true" && {
                    width: width * scale,
                    height: height * scale,
                    marginTop: menuHeight
                })
            }}>
                <iframe
                    id="responsive-preview"
                    title="Aperçu d'application"
                    className={css.PreviewIframe}
                    style={{
                        transform: `scale(${scale})`,
                        ...(urlParams["fullscreen"] === "true" && {
                            position: "fixed",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0
                        })
                    }}
                    width={width}
                    height={height}
                    src={urlParams["url"]}
                />
            </div>
        </div>
    )
}

export default Preview;
