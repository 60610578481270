import React, {Component} from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';

import css from './ActionsBar.module.css';

class ActionsBar extends Component {
    render() {
        const {width, orientation, scale, handleOrientation, handleIframe, urlParams} = this.props;

        let linkUrl = window.location.origin;
        Object.keys(urlParams).forEach((key, index) => {
            linkUrl += index === 0 ? "?" : "&";
            if (key === "url") {
                linkUrl += `url=${encodeURIComponent(urlParams["url"].replace("_clear=false", "_clear=true"))}`;
            } else {
                linkUrl += `${key}=${urlParams[key]}`;
            }
        });

        if (urlParams["fullscreen"] === "true") {
            return (
                <a href={linkUrl} className={css.RefreshButton} style={{
                    position: "fixed",
                    zIndex: 9999,
                    right: 10,
                    top: 10,
                    backgroundColor: "#299ace",
                    padding: 10,
                    borderRadius: 5
                }}>
                    <RefreshIcon/> Actualiser
                </a>
            )
        }

        return (
            <div className={css.ActionsBar}>
                <div
                    className={width === 1920 || width === 1080 ? [css.ActionButton, css.Active].join(" ") : css.ActionButton}
                    onClick={() => orientation === "landscape" ? handleIframe(1920, 1080) : handleIframe(1080, 1920)}>
                    1920x1080
                </div>
                <div
                    className={width === 1024 || width === 768 ? [css.ActionButton, css.Active].join(" ") : css.ActionButton}
                    onClick={() => orientation === "landscape" ? handleIframe(1024, 768) : handleIframe(768, 1024)}>
                    1024x768
                </div>
                <div
                    className={width === 640 || width === 384 ? [css.ActionButton, css.Active].join(" ") : css.ActionButton}
                    onClick={() => orientation === "landscape" ? handleIframe(640, 384) : handleIframe(384, 640)}>
                    640x384
                </div>
                <div className={[css.ActionButton, css.Active].join(" ")} onClick={handleOrientation}>{orientation === "landscape" ? "Paysage" : "Portrait"}</div>
                <div>{scale * 100}%</div>
                <a href={linkUrl} className={css.RefreshButton}>
                    <RefreshIcon/> Actualiser
                </a>
            </div>
        )
    }
}
export default ActionsBar;
