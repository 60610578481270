import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Preview from './_scenes/Preview/Preview';

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={'/'} exact component={Preview}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default App;
